(function () {
  'use strict';

  var queryAll = function (selector, pContext) {
    var context = pContext || document;
    var result = context.querySelectorAll(selector);
    var resultArray = [];

    for (var i = 0, length = result.length; i < length; i += 1) {
      var curr = result[i];
      resultArray.push(curr);
    }

    return resultArray;
  };

  var query = function (selector, pContext) {
    var context = pContext || document;

    return context.querySelector(selector);
  };

  var mirroredList = [];

  var createMirrored = function (mirroredElement) {
    return {
      element: mirroredElement
    };
  };

  var initMirrored = function (mirrored) {
    var classAttribute = mirrored.element.getAttribute('class');

    mirrored.element.setAttribute('class', classAttribute + ' mirrored-height--active');
    mirrored.spacerElement = mirrored.element.parentNode.insertBefore(document.createElement('div'), mirrored.element);
  };

  var checkHeight = function () {
    mirroredList.forEach(function (mirrored) {
      if (mirrored.spacerElement.offsetHeight !== mirrored.element.offsetHeight) {
        mirrored.spacerElement.style.height = mirrored.element.offsetHeight + 'px';
      }
    });
    window.requestAnimationFrame(checkHeight);
  };

  window.scala.documentReady.then(function () {
    mirroredList = queryAll('.mirrored-height').map(createMirrored);
    mirroredList.forEach(initMirrored);
    window.requestAnimationFrame(checkHeight);
  });
}());
